<template>
  <view-base>
    <v-form :id="formId" v-model="formIsValid" ref="form" @submit.prevent="submitForm">
      <v-row class="d-flex flex-column">
        <v-col class="flex-shrink-1 flex-grow-0 prd-maxwidth-100">
          <the-tools-bar>
            <template #tools-right>
              <tool-bar-btns
                :formId="formId"
                :submit-loading="submitLoading"
                @closeItem="closeItem"
                @handleCloseAfterSubmit="handleCloseAfterSubmit($event)"
              />
            </template>
          </the-tools-bar>
        </v-col>

        <v-col v-if="isLoading" class="d-flex justify-center fill-height prd-maxwidth-100 pt-8">
          <v-progress-circular class="my-12" :size="50" :color="loadingColor" indeterminate />
        </v-col>

        <v-col v-else>
          <v-row>
            <v-col cols="12">
              <info-card :form-data="formData" />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
              <personal-data-card :form-data="formData"></personal-data-card>
            </v-col>
            <v-col cols="12" sm="8" lg="9">
              <v-row no-gutters>
                <v-col cols="12">
                  <professional-data-card :form-data="formData"></professional-data-card>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <contact-data-card :form-data="formData" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </view-base>
</template>

<script>
import crudMdEditMixin from "@/mixins/crudMdEditMixin";

export default {
  mixins: [crudMdEditMixin],
  components: {
    contactDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/students-crud-edit/ContactDataCard"
      );
    },
    personalDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/students-crud-edit/PersonalDataCard"
      );
    },
    professionalDataCard: () => {
      return import(
        "@/modules/cardioguard-admin/components/views/students-crud-edit/ProfessionalDataCard"
      );
    },
    infoCard: () => {
      return import("@/modules/cardioguard-admin/components/views/students-crud-edit/InfoCard");
    },
    ViewBase: () => import("@/layouts/intranet-tabs/ViewBase"),
    TheToolsBar: () => import("@/components/TheToolsBar.vue"),
    ToolBarBtns: () => import("@/components/crud-md/actions/EditToolBarBtns"),
  },
  data: () => ({
    formIsValid: null,
    formData: {},
  }),
  beforeMount() {
    this.setLoadingColor();
    this.currentService = this.$store.state.currentService;
    this.itemId = this.$route.params["id"];
    this.itemTitle = "#" + this.itemId;
    this.updateViewTitle();
  },
  async mounted() {
    await this.getStudentData();
    this.itemTitle = `#${this.formData.code} - ${this.formData.name} ${this.formData.surname}`;
    await this.updateViewTitle();
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        try {
          this.submitLoading = true;

          const response = await this.currentService.updateStudentData(this.itemId, this.formData);
          const { errorMessage } = response.data;

          if (errorMessage) {
            this.$notifyError("Datos incorrectos", errorMessage);
          } else {
            this.$notifySuccess();
            if (this.closeAfterSubmit) this.closeItem();
          }
        } catch {
          this.$notifyError();
        } finally {
          this.submitLoading = false;
        }
      }
    },
    async getStudentData() {
      try {
        this.isLoading = true;
        const response = await this.currentService.getStudentData(this.$route.params.id);
        for (const responseKey in response.data) {
          this.$set(this.formData, responseKey, response.data[responseKey]);
        }
      } catch (e) {
        this.$notifyError(undefined, "Error al recuperar los datos del alumno | " + e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
